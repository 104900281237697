import React from 'react';
import { NowPlayingContainer } from './styles';
import banner from '../../assets/banner.webp';
import { LibraryDataRes } from '../../types/res';
import { Stack } from '@mui/material';
import { BaklaagLoader } from './BaklaagLoader';
import { NowPlayingGridItem } from './NowPlayingGridItem';
import { isEmpty } from 'lodash';

export interface NowPlayingProps {
  data: LibraryDataRes[];
}

export const NowPlaying: React.FC<NowPlayingProps> = ({ data }) => {
  const renderGridContent = () => {
    return (
      <Stack paddingInline={'0.5rem'}>
        {data.map((game, i) => (
          <NowPlayingGridItem game={game} key={`game-item-${i}`} />
        ))}
      </Stack>
    );
  };

  return (
    <NowPlayingContainer>
      <img src={banner} />
      <h1>Now Playing</h1>

      {isEmpty(data) ? <BaklaagLoader /> : renderGridContent()}
    </NowPlayingContainer>
  );
};
