import axios from 'axios';
import { useEffect, useState } from 'react';
import { LibraryDataRes, ReviewDataRes } from '../types/res';

export const useLibrary = (platform?: string) => {
  const [libraryData, setLibraryData] = useState<LibraryDataRes[]>([]);
  const [nowPlayingData, setNowPlayingData] = useState<LibraryDataRes[]>([]);
  const [gamesByPlatformData, setGamesByPlatformData] = useState<LibraryDataRes[]>([]);
  const [reviewsByPlatformData, setReviewsByPlatformData] = useState<ReviewDataRes[]>([]);

  const fetchLibrary = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/library`).then((res) => {
        setLibraryData(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNowPlaying = () => {
    try {
      axios.get(`${process.env.REACT_APP_API_URL}/nowPlaying`).then((res) => {
        setNowPlayingData(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGamesByPlatform = () => {
    if (platform) {
      try {
        axios.get(`${process.env.REACT_APP_API_URL}/library/${platform}`).then((res) => {
          setGamesByPlatformData(res.data);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchReviewsByPlatform = () => {
    if (platform) {
      try {
        axios.get(`${process.env.REACT_APP_API_URL}/reviews/${platform}`).then((res) => {
          setReviewsByPlatformData(res.data);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchLibrary();
    fetchNowPlaying();
    fetchGamesByPlatform();
    fetchReviewsByPlatform();
  }, [platform]); // Trigger when the platform changes

  return { libraryData, nowPlayingData, gamesByPlatformData, reviewsByPlatformData };
};
