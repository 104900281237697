import { Stack, Tooltip } from '@mui/material';
import React from 'react';
import {
  BabySpanInfo,
  BeatenProgressBar,
  CompletedProgressBar,
  UnfinishedProgressBar
} from './styles';
import unfinished from '../../assets/icons/unfinished.gif';
import beaten from '../../assets/icons/beaten.gif';
import completed from '../../assets/icons/completed.gif';
import wishlist from '../../assets/icons/wishlist.gif';
import masterRun from '../../assets/icons/mastered.gif';
import topRated from '../../assets/icons/top_rated.gif';

import { LibraryDataRes } from '../../types/res';
import { isEmpty } from 'lodash';

export interface ProgressBarProps {
  data: LibraryDataRes[];
}

function calculatePercentage(partialValue: number, totalValue: number) {
  if (!partialValue || !totalValue) {
    return 0;
  }
  return (100 * partialValue) / totalValue;
}

export const ProgressBarWidget: React.FC<ProgressBarProps> = ({ data }) => {
  const totalGames = data.filter((g) => g.status !== 'None').length;
  const unfinishedGames = data.filter((games) => games.status === 'Unfinished');
  const unstartedGames = data.filter((games) => games.status === 'Unplayed');
  const beatenGames = data.filter((games) => games.status === 'Beaten');
  const completedGames = data.filter((games) => games.status === 'Completed');

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent={'space-evenly'}
        sx={{ fontWeight: 'bold', padding: '20px 0' }}>
        <div>
          <img src={unfinished} /> Unfinished{' '}
          <Tooltip title="Add">
            <BabySpanInfo>?</BabySpanInfo>
          </Tooltip>{' '}
        </div>
        <div>
          <img src={beaten} /> Beaten{' '}
          <Tooltip title="Add">
            <BabySpanInfo>?</BabySpanInfo>
          </Tooltip>{' '}
        </div>
        <div>
          <img src={completed} /> Completed{' '}
          <Tooltip title="Add">
            <BabySpanInfo>?</BabySpanInfo>
          </Tooltip>
        </div>
      </Stack>
      <Stack gap={1} direction={'column'} sx={{ alignItems: 'flex-end' }}>
        <Stack direction={'row'}>
          {unfinishedGames.length + unstartedGames.length} <img src={unfinished} />
          <UnfinishedProgressBar
            variant={isEmpty(data) ? 'indeterminate' : 'buffer'}
            valueBuffer={calculatePercentage(
              unfinishedGames.length + unstartedGames.length,
              totalGames
            )}
            value={calculatePercentage(unstartedGames.length, totalGames)}
          />
          {`${calculatePercentage(
            unfinishedGames.length + unstartedGames.length,
            totalGames
          ).toFixed(1)}%`}
        </Stack>
        <Stack direction={'row'}>
          {beatenGames.length} <img src={beaten} />
          <BeatenProgressBar
            variant={isEmpty(data) ? 'indeterminate' : 'determinate'}
            value={calculatePercentage(beatenGames.length, totalGames)}
          />
          {`${calculatePercentage(beatenGames.length, totalGames).toFixed(1)}%`}
        </Stack>
        <Stack direction={'row'}>
          {completedGames.length} <img src={completed} />
          <CompletedProgressBar
            variant={isEmpty(data) ? 'indeterminate' : 'determinate'}
            value={calculatePercentage(completedGames.length, totalGames)}
          />
          {`${calculatePercentage(completedGames.length, totalGames).toFixed(1)}%`}{' '}
        </Stack>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'space-evenly'}
        sx={{ fontWeight: 'bold', padding: '20px 0' }}>
        <div>
          <img src={wishlist} /> Wishlist{' '}
        </div>
        <div>
          <img src={topRated} /> Top-Rated{' '}
        </div>
        <div>
          <img src={masterRun} /> Master Runs{' '}
        </div>
      </Stack>
    </>
  );
};
