import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/material';
import { LibraryDataRes } from '../../types/res';
import {
  isOlderThanTwoYears,
  parseLabelPerPlatform,
  renderIconPerStatus
} from '../../utils/now-playing-utils';
import { NowPlayingStackWrapper } from './styles';

export interface NowPlayingGridItemProps {
  game: LibraryDataRes;
  key: string;
}

export const NowPlayingGridItem: React.FC<NowPlayingGridItemProps> = ({ game, key }) => {
  const { status, platform, title, notes, lastUpdated } = game;
  const lastUpdatedOver2yrs = isOlderThanTwoYears(lastUpdated);
  return (
    <NowPlayingStackWrapper direction={'row'}>
      <Accordion
        sx={{
          width: '100%',
          backgroundColor: 'transparent',
          boxShadow: 'none',
          color: 'inherit',
          padding: '0',
          minHeight: 'auto',
          button: {
            minHeight: '1rem',
            margin: 0,
            ' .Mui-expanded': { minHeight: '1rem' }
          },
          span: { margin: 0 }
        }}>
        <AccordionSummary
          expandIcon={
            <div
              style={{
                transform: 'rotate(90deg)',
                color: lastUpdatedOver2yrs ? '#79a181' : 'inherit'
              }}>
              ▷
            </div>
          }
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ padding: 0, margin: 0 }}>
          <Box
            key={key}
            flex={0.5}
            textAlign={'left'}
            color={lastUpdatedOver2yrs ? '#79a181' : 'inherit'}>
            {renderIconPerStatus(status)} {parseLabelPerPlatform(platform)}
          </Box>
          <Box
            flex={2}
            textAlign={'left'}
            fontWeight={'bold'}
            color={lastUpdatedOver2yrs ? '#79a181' : 'inherit'}>
            {title}
          </Box>
          <Box flex={2} color={lastUpdatedOver2yrs ? '#79a181' : 'inherit'}>
            {notes}
          </Box>
        </AccordionSummary>
        <AccordionDetails>Last updated about {lastUpdated.toString()}</AccordionDetails>
      </Accordion>
    </NowPlayingStackWrapper>
  );
};
