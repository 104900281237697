import React from 'react';
import goldstar from '../../assets/icons/100star_tiny.gif';
import emptystar from '../../assets/icons/00star_tiny.gif';

export const RatingStars: React.FC<{ value: number }> = ({ value }) => {
  const tinyGoldenStar = <img src={goldstar} />;
  const emptyStar = <img src={emptystar} />;
  const howManyEmptyStarsToRender = 5 - value;

  if (howManyEmptyStarsToRender === 5) return <></>;

  return (
    <div>
      {Array.from(Array(value)).map(() => tinyGoldenStar)}
      {Array.from(Array(howManyEmptyStarsToRender)).map(() => emptyStar)}
    </div>
  );

  //   const b = [value].map(() => emptyStar);
  //   return b;
};
