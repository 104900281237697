import React from 'react';

import unfinished from '../assets/icons/unfinished.gif';
import beaten from '../assets/icons/beaten.gif';
import completed from '../assets/icons/completed.gif';
import none from '../assets/icons/none.gif';

export const renderIconPerStatus = (status: string): React.ReactNode => {
  switch (status) {
    case 'Unfinished':
      return <img src={unfinished} />;
    case 'Beaten':
      return <img src={beaten} />;
    case 'Completed':
      return <img src={completed} />;
    case 'None':
      return <img src={none} />;
    default:
      return '';
  }
};

export const parseLabelPerPlatform = (console: string): string => {
  const labelMap: Record<string, string> = {
    'Nintendo Switch': 'Switch',
    'Nintendo 3DS': '3DS',
    'Nintendo DS': 'NDS',
    'Wii U': 'WiiU',
    'PlayStation Vita': 'PSV',
    'PlayStation 4': 'PS4',
    'PlayStation 3': 'PS3',
    'PlayStation 2': 'PS2',
    PlayStation: 'PS1',
    'Game Boy Advance': 'GBA',
    'Game Boy Color': 'GBC',
    'Super Nintendo Entertainment System': 'SNES'
  };
  return labelMap[console] ?? console;
};

export const isOlderThanTwoYears = (date: string): boolean => {
  const parsedDate = new Date(date);
  const currentDate = new Date();
  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(currentDate.getFullYear() - 2);

  console.log('🚀 ~ isOlderThanTwoYears ~ twoYearsAgo:', twoYearsAgo);
  return parsedDate < twoYearsAgo;
};
