import React from 'react';
import { CuteH1, PopoutIcon } from './styles';
import B_unfinished from '../../assets/icons/B_unfinished.gif';
import B_beaten from '../../assets/icons/B_beaten.gif';
import B_note from '../../assets/icons/B_note.gif';
import B_mcard from '../../assets/icons/B_mcard.gif';
import B_comments from '../../assets/icons/B_comments.gif';
import B_multitap from '../../assets/icons/B_multitap.gif';

interface CuteTitleProps {
  isTab?: boolean;
  icon?: string;
  children: React.ReactNode;
}

export const CuteTitleWithPopout: React.FC<CuteTitleProps> = ({ isTab, icon, children }) => {
  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'unfinished':
        return B_unfinished;
      case 'beaten':
        return B_beaten;
      case 'note':
        return B_note;
      case 'multitap':
        return B_multitap;
      case 'comments':
        return B_comments;
      case 'mcard':
        return B_mcard;
      default:
        return '';
    }
  };

  return isTab ? (
    <></>
  ) : (
    <CuteH1 paddingLeft="55px">
      {icon && <PopoutIcon src={renderIcon(icon)} />}
      {children}
    </CuteH1>
  );
};
