import { Divider, Grid2 as Grid, Stack } from '@mui/material';
import React from 'react';
import {
  CuteH1,
  RedCardContainer,
  SingleGameCardAccordion,
  SingleGameCardAccordionSummary,
  SingleGameCardContainer
} from './styles';
import { useParams } from 'react-router-dom';
import { useLibrary } from '../../hooks/useLibrary';
import unfinished from '../../assets/icons/unfinished.gif';
import unplayed from '../../assets/icons/unplayed.gif';
import beaten from '../../assets/icons/beaten.gif';
import completed from '../../assets/icons/completed.gif';
import none from '../../assets/icons/none.gif';
import { parseLabelPerPlatform } from '../../utils/now-playing-utils';
import { RatingStars } from './RatingStars';

export const ConsoleViewLayout: React.FC = () => {
  const { platform } = useParams();
  const { gamesByPlatformData, reviewsByPlatformData } = useLibrary(platform);

  const gamesWithoutWishlisted = gamesByPlatformData.filter(
    (game) => game.ownership !== 'Wishlist'
  );

  const childGamesId = gamesByPlatformData.map((game) => game.childOf).filter((exist) => exist);
  const parentGames = gamesByPlatformData.filter((game) => childGamesId.includes(game.game_id));
  const parentGamesId = parentGames.map((p) => p.game_id);

  const renderGameStatusIcon = (icon: string) => {
    switch (icon) {
      case 'unfinished':
        return unfinished;
      case 'unplayed':
        return unplayed;
      case 'beaten':
        return beaten;
      case 'completed':
        return completed;
      case 'none':
        return none;

      default:
        return '';
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid size={9}>
        <RedCardContainer>info box</RedCardContainer>
        <RedCardContainer>game progress bar</RedCardContainer>
        <RedCardContainer>
          {gamesWithoutWishlisted.map((game, i) => {
            const gameRating = reviewsByPlatformData.find(
              (reviewGame) => game.game_id === reviewGame.game_id
            )?.Rating;
            if (parentGamesId.includes(game.game_id))
              return (
                <SingleGameCardAccordion key={i} defaultExpanded>
                  <SingleGameCardAccordionSummary
                    sx={{ padding: 0, margin: 0, width: '100%' }}
                    expandIcon={<div style={{ transform: 'rotate(90deg)' }}>▷</div>}>
                    {/* <SingleGameCardContainer> */}
                    <h1>
                      <b>{game.title}</b>
                    </h1>
                    {/* </SingleGameCardContainer> */}
                  </SingleGameCardAccordionSummary>
                </SingleGameCardAccordion>
              );
            return (
              <SingleGameCardContainer key={`${platform}-${game}${i}`}>
                <CuteH1 paddingLeft="1rem">
                  <img src={renderGameStatusIcon(game.status.toLowerCase())} /> <b>{game.title}</b>
                </CuteH1>
                <Stack
                  direction="column"
                  className="content"
                  paddingInline={'1rem'}
                  paddingBlock={'0.25rem'}
                  gap={'0.25rem'}>
                  <Stack justifyContent={'space-between'} direction={'row'}>
                    <Stack direction={'row'} gap={'1rem'}>
                      <b>{parseLabelPerPlatform(game.platform)}</b>
                      <RatingStars value={gameRating ?? 0} />
                    </Stack>
                    <div></div>
                  </Stack>
                  {!!game.notes && <Divider />}
                  <Stack textAlign={'left'}>{game.notes}</Stack>
                </Stack>
              </SingleGameCardContainer>
            );
          })}
        </RedCardContainer>
      </Grid>
      <Grid size={3}>
        <RedCardContainer>games</RedCardContainer>
        <RedCardContainer>filters </RedCardContainer>
      </Grid>
    </Grid>
  );
};
